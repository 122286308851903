import Layout from '../components/Layout';
import ImageWrapper from '../components/ImageWrapper';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import './thanks.scss';

interface Props {
  data: any;
}

const Thanks: FC<Props> = ({ data }: Props) => {
  return (
    <Layout>
      <div className="thanks">
        <h2>Vielen Dank für Ihr Interesse.</h2>
        <p>
          Ich werde mich schnellstmöglich um Ihre Anfrage kümmern.
        </p>
        <br />
        <ImageWrapper noClick>
          <GatsbyImage
            loading="lazy"
            image={data.contentfulContact.thanksImage.gatsbyImageData}
            alt={data.contentfulContact.thanksImage.title}
          />
        </ImageWrapper>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ThanksPage {
    contentfulContact {
      thanksImage {
        title
        gatsbyImageData
      }
    }
  }
`;

export default Thanks;
