import React from 'react';
import classNames from 'classnames';
import './ImageWrapper.scss';

const BLOCK_CLASSNAME = 'image-wrapper';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  overlay?: string;
  noClick?: boolean;
}

const ImageWrapper: React.FC<Props> = ({ children, onClick, overlay, noClick }: Props) => {
  const classes = classNames(BLOCK_CLASSNAME, {
    [`${BLOCK_CLASSNAME}--no-click`]: noClick,
  });

  return (
    <div className={classes}>
      <div
        onClick={onClick}
        onKeyDown={(e): void => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            if (onClick) {
              onClick();
            }
          }
        }}
        className={`${BLOCK_CLASSNAME}__img`}
        tabIndex={0}
      >
        {children}
        {overlay && (
          <div className={`${BLOCK_CLASSNAME}__overlay`}>
            <div className={`${BLOCK_CLASSNAME}__overlay-text`}>{overlay}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageWrapper;
